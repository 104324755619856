import React, { FunctionComponent } from 'react'
import Model, { ModelAttributeId } from '../../entities/Model'
import Remark from '../../entities/Remark'
import ModelsTableRow from './ModelsTableRow'
import ModelTableRowPlaceholder from './ModelTableRowPlaceholder'
import DisclaimerText, { getRemarkLabels } from '../disclaimer-text'
import AttributeFilterResultPlaceholder from '../attribute-filters-placeholder'
import style from './modelsTable.css'
import { Table } from '../table'

export type Props = {
    models: Model[]
    viewAttributes: ModelAttributeId[]
    remarks: Remark[]
    isLoading: boolean
    selectModel: Function
    deselectModel: Function
    openInfoModal: Function
}

const ModelsTable: FunctionComponent<Props> = (props) => {
    const {
        models,
        viewAttributes,
        isLoading,
        remarks,
        selectModel,
        deselectModel,
        openInfoModal,
    } = props

    const remarkLabels = getRemarkLabels(remarks)

    const tableRows = (models.length === 0 && isLoading)
        ? <ModelTableRowPlaceholder />
        : models.map((model) => (
            <ModelsTableRow
                key={model.id}
                model={model}
                viewAttributes={viewAttributes}
                remarkLabels={remarkLabels}
                selectModel={selectModel}
                deselectModel={deselectModel}
                openInfoModal={openInfoModal}
            />
        ))

    return (
        <>
            <AttributeFilterResultPlaceholder hasAvailableResults={models?.length > 0}>
            <Table className={style.table}>
                <tbody>
                    {tableRows}
                </tbody>
            </Table>
            </AttributeFilterResultPlaceholder>
            <DisclaimerText remarks={remarks} />
        </>
    )
}

export default ModelsTable
