import { connect, MapStateToProps } from 'react-redux'
import { Props } from './FastConfigurationButton'
import isFastConfigurationEnabledSelector from '../../redux/selectors/feature-scope/isFastConfigurationEnabledSelector'
import allModelsRequestIsPendingSelector from '../../redux/selectors/pending-request/allModelsRequestIsPendingSelector'

type StateProps = Pick<Props, 'isVisible' | 'isDisabled' >

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    isVisible: isFastConfigurationEnabledSelector(state),
    isDisabled: allModelsRequestIsPendingSelector(state),
})

export default connect(mapStateToProps)
