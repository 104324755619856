import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import { stringify } from 'query-string'
import {
    fetchEntryData,
    fetchEntryDataAsync,
    setEntryDataState,
} from '../../actions/app/entryData.actions'
import { ApiRequest } from '../../apiRequest'
import { getEntryApiUrl } from '../../../constants/apiUrls'
import initParametersSelector from '../../selectors/initParametersSelector'
import { fetchCarlineData } from '../../actions/app/carlineData.actions'
import { applyBrand } from '../../actions/app/brand.actions'
import { createNotification } from '../../actions/app/notification.actions'
import LinkData from '../../../entities/LinkData'
import i18n from '../../../i18n/i18n'
import { fetchComparisonCarlineStructure } from '../../actions/app/comparisonCarlineStructure.actions'
import isCompassEnabledSelector from '../../selectors/feature-scope/isCompassEnabledSelector'
import isOrderButtonEnabledSelector from '../../selectors/feature-scope/isOrderButtonEnabledSelector'
import isVwnRecommendationsEnabledSelector from '../../selectors/feature-scope/isVwnRecommendationsEnabledSelector'
import { fetchModelsWithRecommendations } from '../../actions/app/recommendation.actions'
import isFastConfigurationEnabledSelector from '../../selectors/feature-scope/isFastConfigurationEnabledSelector'
import { fetchAllModels } from '../../actions/app/models.actions'

const noReturnUrl = (links: LinkData[]): boolean => {
    let result = true
    if (links) {
        links.forEach(link => {
            if(link.id === "orderUrl") {
                result = false
            }
        })
    }
    return result
}

// eslint-disable-next-line max-len
const entryDataMiddleware = (apiRequest: ApiRequest): Middleware => (store) => (next) => (action) => {
    next(action)
    const { dispatch, getState } = store

    if (isActionOf(fetchEntryData, action)) {
        const state = getState()
        const params = initParametersSelector(state)
        const paramsWithoutContextAndFeatures = {
            ...params,
        }

        delete paramsWithoutContextAndFeatures.contextId
        delete paramsWithoutContextAndFeatures.features

        const requestParams = stringify(paramsWithoutContextAndFeatures, {
            skipNull: true,
        })

        apiRequest({
            options: {
                url: getEntryApiUrl(params.contextId, requestParams),
                method: 'GET',
            },
            asyncActions: fetchEntryDataAsync,
            causedBy: action,
        }, dispatch)
    }

    if (isActionOf(fetchEntryDataAsync.success, action)) {
        dispatch(setEntryDataState(action.payload))
        const state = getState()
        const isCompassEnabled = isCompassEnabledSelector(state)
        const isOrderButtonEnabled = isOrderButtonEnabledSelector(state)
        const isVwnRecommendationsEnabled = isVwnRecommendationsEnabledSelector(state)
        const isFastConfigurationEnabled = isFastConfigurationEnabledSelector(state)

        dispatch(applyBrand())
        dispatch(fetchCarlineData())
        // dispatch(fetchCarlineFiltersData()) moved to carlineDataMiddleware
        if(action.payload){
            if(isOrderButtonEnabled && noReturnUrl(action.payload.links)){
                dispatch(createNotification({
                    type: 'error',
                    message: i18n.t('notifications.no-return-url'),
                }))
            }
            if(isCompassEnabled){
                dispatch(fetchComparisonCarlineStructure())
            }
        }

        if (isVwnRecommendationsEnabled) {
            dispatch(fetchModelsWithRecommendations())
        }

        if (isFastConfigurationEnabled) {
            dispatch(fetchAllModels())
        }
    }
}

export default entryDataMiddleware
