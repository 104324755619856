import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import { push } from 'connected-react-router'
import {
    fetchModels,
    fetchModelsAsync,
    setModelDataState,
    selectModel,
    deselectModel, fetchAllModels, fetchAllModelsAsync, setAllModelsDataSate,
} from '../../actions/app/models.actions'
import {getAllModelsApiUrl, getModelsApiUrl} from '../../../constants/apiUrls'
import { putConfiguration } from '../../actions/app/configuration.actions'
import { openInfoModal } from '../../actions/app/infoModal.actions'
import { ROUTE_CARLINES } from '../../../constants/routes'
import { ApiRequest } from '../../apiRequest'
import { resetAudiExclusiveDataState } from '../../actions/app/audiExclusiveApp.actions'
import { resetVehicleCodeState } from '../../actions/app/vehicleCode.actions'

// eslint-disable-next-line max-len
const modelDataMiddleware = (apiRequest: ApiRequest): Middleware => (store) => (next) => (action) => {
    next(action)
    const { dispatch } = store

    if (isActionOf(fetchModels, action)) {
        const carlineId = action.payload

        apiRequest({
            options: {
                url: getModelsApiUrl(carlineId),
                method: 'GET',
            },
            asyncActions: fetchModelsAsync,
            causedBy: action,
        }, dispatch)
    }

    if (isActionOf(fetchAllModels, action)) {

        apiRequest({
            options: {
                url: getAllModelsApiUrl(),
                method: 'GET',
            },
            asyncActions: fetchAllModelsAsync,
            causedBy: action,
        }, dispatch)
    }

    if (isActionOf(fetchAllModelsAsync.success, action)) {
        const allModelsData = action.payload
        dispatch(setAllModelsDataSate(allModelsData))
    }

    if (isActionOf(fetchModelsAsync.success, action)) {
        const modelData = action.payload

        dispatch(setModelDataState(modelData))
        dispatch(resetAudiExclusiveDataState())
    }

    if (isActionOf(fetchModelsAsync.failure, action)) {
        dispatch(push(ROUTE_CARLINES))
    }

    if (isActionOf(selectModel, action)) {
        const modelId = action.payload

        if (action.meta && action.meta.infoModal) {
            dispatch(openInfoModal({
                ...action.meta.infoModal,
                onClose: selectModel(modelId, undefined),
            }))
        } else {
            dispatch(putConfiguration({
                id: modelId,
                method: 'add',
            }))
        }

        dispatch(resetVehicleCodeState())
    }

    if (isActionOf(deselectModel, action)) {
        dispatch(putConfiguration({
            id: action.payload,
            method: 'remove',
        }))
    }
}

export default modelDataMiddleware
