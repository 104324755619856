import React, { useMemo, useState } from 'react'
import Select from 'react-select'
import { Tr, Td } from '../table'
import style from '../fast-configuration-modal/fastConfigurationModal.css'
import useTranslation from '../../hooks/useTranslation'

interface ColorOption {
    value: string
    label: string
}

const FastConfigurationInteriorAutocomplete = ({ equipmentGroups, selectedInteriorColor, selectedModel, setSelectedInteriorColor, isLoading }) => {
    const { t } = useTranslation()
    const [interiorInputValue, setInteriorInputValue] = useState('')

    const interiorColorOptions: ColorOption[] = useMemo(() => {
        const colorSet = new Set<string>()
        equipmentGroups
            .flatMap(group => group.equipmentFamilies)
            .flatMap(family => family.equipments)
            .filter(equipment => equipment.id.startsWith('F56') || equipment.id.startsWith('K-'))
            .forEach(equipment => {
                const label = equipment.id
                colorSet.add(label)
            })
        return Array.from(colorSet).map((label): ColorOption => ({ value: label, label }))
    }, [equipmentGroups])

    const filteredInteriorColorOptions = useMemo(() => interiorInputValue.length >= 2
        ? interiorColorOptions.filter(option =>
            (option.label as string).toLowerCase().endsWith(interiorInputValue.toLowerCase()),
          )
        : [], [interiorInputValue, interiorColorOptions])

    return (
        <Tr>
            <Td className={style.noBorder}>
                <div className={style.explanationContainer}>
                    <h4 className={style.explanationHeading}>
                        {t('fastConfigurationModal.interiorTitle')}
                    </h4>
                    <span className={style.explanation}>
                        {t('fastConfigurationModal.interiorExplanation')}
                    </span>
                </div>
            </Td>
            <Td className={style.noBorder}>
                    <Select
                        options={filteredInteriorColorOptions}
                        value={selectedInteriorColor}
                        onChange={(selected) => setSelectedInteriorColor(selected || null)}
                        onInputChange={(value) => setInteriorInputValue(value)}
                        isSearchable
                        isClearable
                        placeholder=""
                        className={style.input}
                        isDisabled={isLoading || selectedModel == null}
                        components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                        }}
                        noOptionsMessage={() =>
                            interiorInputValue.length < 2
                                ? t('fastConfigurationModal.atLeastTwoCharacters')
                                : t('fastConfigurationModal.noInteriorColor')
                        }
                    />
            </Td>
        </Tr>
    )
}

export default FastConfigurationInteriorAutocomplete
