import { createReducer } from 'typesafe-actions'
import EquipmentGroup from '../../entities/EquipmentGroup'
import {
    resetEquipmentGroupsForCarlineAndModelState,
    setEquipmentGroupsForCarlineAndModelState,
} from '../actions/app/equipmentGroups.actions'

export type EquipmentGroupsForCarlineAndModelState = EquipmentGroup[]

export const defaultState: EquipmentGroupsForCarlineAndModelState = []

const equipmentGroupsForCarlineAndModelReducer = createReducer(defaultState)
    .handleAction(setEquipmentGroupsForCarlineAndModelState, (state, action) => action.payload)
    .handleAction(resetEquipmentGroupsForCarlineAndModelState, () => defaultState)

export default equipmentGroupsForCarlineAndModelReducer
