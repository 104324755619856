import React, { useMemo, useState } from 'react'
import Select from 'react-select'
import { Tr, Td } from '../table'
import { Spinner } from '../../base-components'
import style from '../fast-configuration-modal/fastConfigurationModal.css'
import useTranslation from '../../hooks/useTranslation'

const FastConfigurationEquipmentAutocomplete = ({ equipmentGroups, equipments, selectedModel, setEquipments, isLoading }) => {
    const { t } = useTranslation()
    const [equipmentsInputValue, setEquipmentsInputValue] = useState('')
    const [error, setError] = useState('')
    const [matchedOptions, setMatchedOptions] = useState([])

    const equipmentOptions = useMemo(() => equipmentGroups
        .flatMap(group => group.equipmentFamilies)
        .flatMap(family => family.equipments)
        .filter(equipment => !equipment.isStandard)
        .filter(equipment => !equipment.id.startsWith('F14') && !equipment.id.startsWith('F56') && !equipment.id.startsWith('K-') && !equipment.id.startsWith('Z'))
        .flatMap(equipment => {
            const options = [{ value: equipment.id, label: equipment.id }]
            if (equipment.isPackage && equipment.content) {
                equipment.content.forEach(content => {
                    options.push({
                        value: equipment.id,
                        label: `${content.id} (${equipment.id})`,
                    })
                })
            }
            return options
        }), [equipmentGroups])

    const filteredEquipmentsOptions = useMemo(() => equipmentsInputValue.length >= 3
        ? equipmentOptions.filter(option =>
            option.label.toLowerCase().endsWith(equipmentsInputValue.toLowerCase()),
          )
        : [], [equipmentsInputValue, equipmentOptions])

    const handlePaste = (event) => {
        const pastedData = event.clipboardData.getData('Text')
        const splitData = pastedData.split(/[,| ]+/)
        const newMatchedOptions = splitData.map(data => {
            if (data.length === 3) {
                const option = equipmentOptions.find(option => option.label.toLowerCase().endsWith(data.toLowerCase()))
                return option ? option : undefined
            } else {
                return equipmentOptions.find(option => option.label.toLowerCase().endsWith(data.toLowerCase()))
            }
        }).filter(option => option !== undefined)

        const notFoundEquipments = splitData.filter(data =>
            !newMatchedOptions.some(option => option.label.toLowerCase().endsWith(data.toLowerCase())),
        )

        if (notFoundEquipments.length > 0) {
            setError(`${t('fastConfigurationModal.noEquipmentsFound')} ${notFoundEquipments.join(', ')}`)
        } else {
            setError('')
        }

        setMatchedOptions(newMatchedOptions)
        setEquipments(newMatchedOptions)
    }

    const handleSelectChange = (selected) => {
        setEquipments(selected || [])
        setMatchedOptions(selected || [])
        if (!selected || selected.length === 0) {
            setError('')
        }
    }

    return (
        <Tr>
            <Td className={style.noBorder}>
                <div className={style.explanationContainer}>
                    <h4 className={style.explanationHeading}>
                        {t('fastConfigurationModal.equipmentsTitle')}
                    </h4>
                    <span className={style.explanation}>
                        {t('fastConfigurationModal.equipmentsExplanation')}
                    </span>
                </div>
            </Td>
            <Td className={style.noBorder}>
                {isLoading ? <div className={style.spinnerContainer}><Spinner /></div> : (
                   <div onPaste={handlePaste}>
                       <Select
                            options={filteredEquipmentsOptions}
                            value={equipments}
                            onChange={handleSelectChange}
                            onInputChange={(value) => setEquipmentsInputValue(value)}
                            onPaste={handlePaste}
                            isMulti
                            isDisabled={selectedModel == null}
                            isSearchable
                            isClearable
                            placeholder=""
                            className={style.input}
                            components={{
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null,
                            }}
                            noOptionsMessage={() =>
                                equipmentsInputValue.length < 3 && matchedOptions.length === 0
                                    ? t('fastConfigurationModal.atLeastThreeCharacters')
                                    : matchedOptions.length === 0
                                    ? t('fastConfigurationModal.noEquipmentsFound')
                                    : null
                            }
                        />
                        {error && <div className={style.error}>{error}</div>}
                   </div>
                )}
            </Td>
        </Tr>
    )
}

export default FastConfigurationEquipmentAutocomplete
