import React, { useMemo, useState } from 'react'
import Select from 'react-select'
import { Tr, Td } from '../table'
import style from '../fast-configuration-modal/fastConfigurationModal.css'
import useTranslation from '../../hooks/useTranslation'

const FastConfigurationExteriorAutocomplete = ({ equipmentGroups, selectedExteriorColor, selectedModel, setSelectedExteriorColor, isLoading }) => {
    const { t } = useTranslation()
    const [exteriorInputValue, setExteriorInputValue] = useState('')

    const exteriorColorOptions = useMemo(() => equipmentGroups
        .flatMap(group => group.equipmentFamilies)
        .flatMap(family => family.equipments)
        .filter(equipment => equipment.id.startsWith('F14'))
        .map(equipment => ({ value: equipment.id, label: equipment.id })), [equipmentGroups])

    const filteredExteriorColorOptions = useMemo(() => exteriorInputValue.length >= 2
        ? exteriorColorOptions.filter(option =>
            option.label.toLowerCase().endsWith(exteriorInputValue.toLowerCase()),
          )
        : [], [exteriorInputValue, exteriorColorOptions])

    return (
        <Tr>
            <Td className={style.noBorder}>
                <div className={style.explanationContainer}>
                    <h4 className={style.explanationHeading}>
                        {t('fastConfigurationModal.exteriorTitle')}
                    </h4>
                    <span className={style.explanation}>
                        {t('fastConfigurationModal.exteriorExplanation')}
                    </span>
                </div>
            </Td>
            <Td className={style.noBorder}>
                    <Select
                        options={filteredExteriorColorOptions}
                        value={selectedExteriorColor}
                        onChange={(selected) => setSelectedExteriorColor(selected || null)}
                        onInputChange={(value) => setExteriorInputValue(value)}
                        isSearchable
                        isClearable
                        isDisabled={isLoading || selectedModel == null}
                        placeholder=""
                        className={style.input}
                        components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                        }}
                        noOptionsMessage={() =>
                            exteriorInputValue.length < 2
                                ? t('fastConfigurationModal.atLeastTwoCharacters')
                                : t('fastConfigurationModal.noExteriorColor')
                        }
                    />
            </Td>
        </Tr>
    )
}

export default FastConfigurationExteriorAutocomplete
