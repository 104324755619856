import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import { resetAudiExclusiveDataState,
    storeAudiExclusiveSelection,
    storeAudiExclusiveSelectionAsync,
    updateAudiExclusiveSelection, updateAudiExclusiveSelectionAsync,
} from '../../actions/app/audiExclusiveApp.actions'
import { ApiRequest } from '../../apiRequest'
import { getAudiExclusiveApiUrl } from '../../../constants/apiUrls'
import { fetchVisualization } from '../../actions/app/visualization.actions'
import { fetchEquipmentGroups } from '../../actions/app/equipmentGroups.actions'
import { fetchCart } from '../../actions/app/cart.actions'

// eslint-disable-next-line max-len
const audiExclusiveMiddleware = (apiRequest: ApiRequest): Middleware => (store) => (next) => (action) => {
    next(action)
    const { dispatch } = store

    if (isActionOf(storeAudiExclusiveSelection, action)) {
        apiRequest({
            options: {
                url: getAudiExclusiveApiUrl(),
                method: 'POST',
                body: JSON.stringify(action.payload),
            },
            asyncActions: storeAudiExclusiveSelectionAsync,
            causedBy: action,
        }, dispatch)
    }

    if (isActionOf(storeAudiExclusiveSelectionAsync.success, action)) {
        dispatch(fetchVisualization())
    }

    if (isActionOf(updateAudiExclusiveSelectionAsync.success, action)) {
        dispatch(resetAudiExclusiveDataState())
        dispatch(fetchEquipmentGroups())
        dispatch(fetchCart())
        dispatch(fetchVisualization())
    }

     if (isActionOf(updateAudiExclusiveSelection, action)) {
        apiRequest({
            options: {
                url: getAudiExclusiveApiUrl(),
                method: 'PATCH',
            },
            asyncActions: updateAudiExclusiveSelectionAsync,
            causedBy: action,
        }, dispatch)
    }
}

export default audiExclusiveMiddleware
