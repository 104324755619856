import { createSelector } from 'reselect'
import FilterOption from '../../../entities/FilterOption'
import modelsSelector from './modelsSelector'
import filterListSelector from '../filterListSelector'
import filtersSelector from '../filtersSelector'

const modelFilterOptionsSelector = createSelector(
    filterListSelector,
    modelsSelector,
    filtersSelector,
    (filterList, models, appliedFilters): FilterOption[] => {
        if (!filterList) {
            return []
        }

        return filterList
            .map((filter) => {
                const values = new Set()

                models.forEach((model) => {
                    const modelAttribute = model.attributes
                        .find((attribute) => attribute.id === filter.attribute)

                    if (modelAttribute) {
                        if (typeof modelAttribute.value === 'string' && filter.type === 'max') {
                            const numValue = parseInt(modelAttribute.value)
                            values.add(numValue)
                        } else {
                            values.add(modelAttribute.value)
                        }
                    }
                })

                return {
                    attribute: filter.attribute,
                    type: filter.type,
                    values: Array.from(values) as string[],
                    activeValues: appliedFilters[filter.attribute] || [],
                }
            })
            .filter((filterOption) => filterOption.values.length > 0)
    },
)

export default modelFilterOptionsSelector
