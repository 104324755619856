import { useState, useEffect } from 'react'
import { fetchEquipmentGroupsForCarlineAndModel } from '../../../redux/actions/app/equipmentGroups.actions'

export const useEquipmentOptions = (dispatch) => {
    const [selectedModel, setSelectedModel] = useState(null)
    const [selectedExteriorColor, setSelectedExteriorColor] = useState(null)
    const [selectedInteriorColor, setSelectedInteriorColor] = useState(null)
    const [equipments, setEquipments] = useState([])

    useEffect(() => {
        if (selectedModel) {
            const [carlineId, modelId] = selectedModel.value.split('|')
            dispatch(fetchEquipmentGroupsForCarlineAndModel({ carlineId, modelId }))
        } else {
            setSelectedExteriorColor(null)
            setSelectedInteriorColor(null)
            setEquipments([])
        }
    }, [selectedModel, dispatch])

    return {
        selectedModel, setSelectedModel,
        selectedExteriorColor, setSelectedExteriorColor,
        selectedInteriorColor, setSelectedInteriorColor,
        equipments, setEquipments,
    }
}
