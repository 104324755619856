import React, { FunctionComponent } from 'react'
import style from './attributeFilterResultPlaceholder.css'
import { FiltersState } from '../../redux/reducers/filtersReducer'
import useTranslation from '../../hooks/useTranslation'

export type Props = {
    selectedFilters: FiltersState
    hasAvailableResults: boolean
    children: React.ReactNode
}

const AttributeFilterResultPlaceholder: FunctionComponent<Props> = (props) => {
    const { selectedFilters, hasAvailableResults,  children  } = props
    const { t } = useTranslation()

    const isEmptyFilters = !selectedFilters
        || !Object.values(selectedFilters).some(arr => arr.length > 0)

    if (isEmptyFilters || hasAvailableResults) {
        return <>{children}</>
    }

    return (
        <section className={style.container}>
            <h2 className={style.title}>{t('filters.resetResultPlaceholder.title')}</h2>

            <div className={style.content}>
                <strong>{t('filters.resetResultPlaceholder.hintTitle')}</strong>
                <h3>{t('filters.resetResultPlaceholder.text')}</h3>
            </div>
        </section>
    )
}

export default AttributeFilterResultPlaceholder
