import {createSelector} from 'reselect'
import pendingRequestsSelector from './pendingRequestsSelector'
import requestIsPending from './helpers/requestIsPending'
import { API_URL_MODELS } from '../../../constants/apiUrls'

const allModelsRequestIsPendingSelector = createSelector(
    pendingRequestsSelector,
    (pendingRequests): boolean => requestIsPending(pendingRequests, API_URL_MODELS),
)

export default allModelsRequestIsPendingSelector
