import { createAction, createAsyncAction } from 'typesafe-actions'
import EquipmentGroup from '../../../entities/EquipmentGroup'
import { EquipmentId } from '../../../entities/Equipment'
import { InfoModalData } from '../../../entities/InfoModal'
import EquipmentResponse from '../../../entities/EquipmentResponse'
import EquipmentForCarlineAndModelRequest from '../../../entities/EquipmentForCarlineAndModelRequest'

export const selectEquipment = createAction('CMD / EquipmentGroups / select equipment')<EquipmentId, { infoModal?: InfoModalData } | undefined>()
export const deselectEquipment = createAction('CMD / EquipmentGroups / deselect equipment')<EquipmentId>()

export const fetchEquipmentGroups = createAction('CMD / EquipmentGroups / fetch')()

export const fetchEquipmentGroupsAsync = createAsyncAction(
    'EVT / EquipmentGroups / fetch request',
    'EVT / EquipmentGroups / fetch success',
    'EVT / EquipmentGroups / fetch failure',
)<undefined, EquipmentResponse, Error>()

export const fetchEquipmentGroupsForCarlineAndModel = createAction('CMD / EquipmentGroupsForCarlineAndModel / fetch')<EquipmentForCarlineAndModelRequest>()

export const fetchEquipmentGroupsForCarlineAndModelAsync = createAsyncAction(
    'EVT / EquipmentGroupsForCarlineAndModel / fetch request',
    'EVT / EquipmentGroupsForCarlineAndModel / fetch success',
    'EVT / EquipmentGroupsForCarlineAndModel / fetch failure',
)<EquipmentForCarlineAndModelRequest, EquipmentResponse, Error>()

export const setEquipmentGroupsState = createAction('DOC / EquipmentGroups / set state')<EquipmentGroup[]>()

export const resetEquipmentGroupsState = createAction('DOC / EquipmentGroups / reset state')()

export const setEquipmentGroupsForCarlineAndModelState = createAction('DOC / EquipmentGroupsForCarlineAndModel / set state')<EquipmentGroup[]>()

export const resetEquipmentGroupsForCarlineAndModelState = createAction('DOC / EquipmentGroupsForCarlineAndModel / reset state')()
