import React, { FunctionComponent, FormEventHandler } from 'react'
import { useDispatch } from 'react-redux'
import useTranslation from '../../hooks/useTranslation'
import Modal, { ModalClosingButton } from '../modal'
import style from './fastConfigurationModal.css'
import { Table } from '../table'

import EquipmentGroup from '../../entities/EquipmentGroup'
import Model from '../../entities/Model'
import { useEquipmentOptions } from '../rescue-vehicle-code-modal/hooks/useEquipmentOptions'
import FastConfigurationModelAutocomplete from '../fast-configuration-model-autocomplete/FastConfigurationModelAutocomplete'
import FastConfigurationExteriorAutocomplete from '../fast-configuration-exterior-autocomplete/FastConfigurationExteriorAutocomplete'
import FastConfigurationInteriorAutocomplete from '../fast-configuration-interior-autocomplete/FastConfigurationInteriorAutocomplete'
import FastConfigurationEquipmentAutocomplete from '../fast-configuration-equipment-autocomplete/FastConfigurationEquipmentAutocomplete'
import { loadConfigurationByPrString } from '../../redux/actions/app/prString.actions'

export type Props = {
    models: Model[]
    equipmentGroupsForCarlineAndModel: EquipmentGroup[]
    equipmentsRequestIsPending: boolean
    activeState: [boolean, (isActive: boolean) => void]
}

const FastConfigurationModal: FunctionComponent<Props> = ({
    models,
    equipmentGroupsForCarlineAndModel,
    equipmentsRequestIsPending,
    activeState,
}) => {
    const { t } = useTranslation()
    const [modalIsActive, setModalIsActive] = activeState
    const dispatch = useDispatch()

    const {
        selectedModel, setSelectedModel,
        selectedExteriorColor, setSelectedExteriorColor,
        selectedInteriorColor, setSelectedInteriorColor,
        equipments, setEquipments,
    } = useEquipmentOptions(dispatch)

    if (!modalIsActive) { return null }

    const closeModal = () => setModalIsActive(false)
    const submitHandler: FormEventHandler = (event) => {
        event.preventDefault()
        const prString = [selectedModel?.value, selectedExteriorColor?.value, selectedInteriorColor?.value, ...equipments.map(e => e.value)].join('|')
        dispatch(loadConfigurationByPrString(prString))
        closeModal()
    }

    return (
        <Modal onClose={closeModal} isVisible={true}>
            <ModalClosingButton onClick={closeModal} />
            <div className={style.container}>
                <h2 className={style.title}>{t('fastConfigurationModal.title')}</h2>
                <h4>{t('fastConfigurationModal.disclaimer')}</h4>
                <form onSubmit={submitHandler}>
                    <Table>
                    <tbody>
                        <FastConfigurationModelAutocomplete models={models} selectedModel={selectedModel}
                        setSelectedModel={setSelectedModel} isLoading={equipmentsRequestIsPending} />

                        <FastConfigurationExteriorAutocomplete equipmentGroups={equipmentGroupsForCarlineAndModel}
                        selectedExteriorColor={selectedExteriorColor} selectedModel={selectedModel}
                        setSelectedExteriorColor={setSelectedExteriorColor} isLoading={equipmentsRequestIsPending} />

                        <FastConfigurationInteriorAutocomplete equipmentGroups={equipmentGroupsForCarlineAndModel}
                        selectedInteriorColor={selectedInteriorColor} selectedModel={selectedModel}
                        setSelectedInteriorColor={setSelectedInteriorColor} isLoading={equipmentsRequestIsPending} />

                        <FastConfigurationEquipmentAutocomplete equipmentGroups={equipmentGroupsForCarlineAndModel}
                        equipments={equipments} selectedModel={selectedModel} setEquipments={setEquipments}
                        isLoading={equipmentsRequestIsPending} />
                    </tbody>
                    </Table>
                    <div className={style.submitContainer}>
                        <button className={style.submitButton} type='submit' disabled={!selectedModel || equipmentsRequestIsPending }>
                            {t('fastConfigurationModal.submitButtonText')}
                        </button>
                    </div>
                </form>
            </div>
        </Modal>
    )
}

export default FastConfigurationModal
