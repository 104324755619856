import { API_URL_PR_STRING } from '../../../constants/apiUrls'
import { createSelector } from 'reselect'
import pendingRequestsSelector from './pendingRequestsSelector'
import requestIsPending from './helpers/requestIsPending'

const prStringRequestIsPendingSelector = createSelector(
    pendingRequestsSelector,
    (pendingRequests): boolean => requestIsPending(pendingRequests, API_URL_PR_STRING),
)

export default prStringRequestIsPendingSelector
