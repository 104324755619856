import { connect, MapStateToProps } from 'react-redux'
import { Props } from './FastConfigurationModal'
import allModelsDataSelector from '../../redux/selectors/models/allModelsSelector'
import equipmentGroupsForCarlineAndModelSelector from '../../redux/selectors/equipments/equipmentGroupsForCarlineAndModelSelector'
import equipmentRequestIsPendingSelector from '../../redux/selectors/pending-request/equipmentRequestIsPendingSelector'

type StateProps = Pick<Props, 'equipmentGroupsForCarlineAndModel' | 'models' | 'equipmentsRequestIsPending'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state) => ({
    models: allModelsDataSelector(state),
    equipmentGroupsForCarlineAndModel: equipmentGroupsForCarlineAndModelSelector(state),
    equipmentsRequestIsPending: equipmentRequestIsPendingSelector(state),
})

export default connect(mapStateToProps)
