import { createSelector } from 'reselect'
import entryDataRequestIsPendingSelector from '../pending-request/entryDataRequestIsPendingSelector'
import vehicleCodeRequestIsPendingSelector from '../pending-request/vehicleCodeRequestIsPendingSelector'
import shallAppRenderSelector from '../shallAppRenderSelector'
import showErrorPageSelector from '../showErrorPageSelector'
import userGarageStoreVehicleRequestIsPendingSelector
    from '../pending-request/userGarageStoreVehicleRequestIsPendingSelector'
import prStringRequestIsPendingSelector from '../pending-request/prStringRequestIsPendingSelector'

const showGlobalLoadingIndicatorSelector = createSelector(
    shallAppRenderSelector,
    entryDataRequestIsPendingSelector,
    vehicleCodeRequestIsPendingSelector,
    userGarageStoreVehicleRequestIsPendingSelector,
    prStringRequestIsPendingSelector,
    showErrorPageSelector,
    (
        shallAppRender,
        entryDataRequestIsPending,
        vehicleCodeRequestIsPending,
        userGarageStoreVehicleRequestIsPending,
        prStringRequestIsPending,
        showErrorPage,
    ): boolean => (
        (
            !shallAppRender
            || entryDataRequestIsPending
            || vehicleCodeRequestIsPending
            || userGarageStoreVehicleRequestIsPending
            || prStringRequestIsPending
        ) && !showErrorPage
    ),
)

export default showGlobalLoadingIndicatorSelector
