import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import { getCreateVehiclecodeApiUrl, loadConfigurationByVehiclecodeApiUrl } from '../../../constants/apiUrls'
import { fetchCart } from '../../actions/app/cart.actions'
import { fetchConfiguration } from '../../actions/app/configuration.actions'
import { fetchEntryDataAsync } from '../../actions/app/entryData.actions'
import { fetchEquipmentGroups } from '../../actions/app/equipmentGroups.actions'
import { resetVehicleCodeInState } from '../../actions/app/initParameters.actions'
import {
    createVehicleCode,
    createVehicleCodeAsync,
    loadConfigurationByVehicleCode,
    loadConfigurationByVehicleCodeAsync,
    resetVehicleCodeState,
    setVehicleCodeState,
    setVehicleCodeStatus,
    vehicleCodeChanged,
} from '../../actions/app/vehicleCode.actions'
import { fetchVisualization } from '../../actions/app/visualization.actions'
import { ApiRequest } from '../../apiRequest'
import initParametersSelector from '../../selectors/initParametersSelector'
import sessionIdIsSetSelector from '../../selectors/sessionIdIsSetSelector'
import { setShouldNavigateToTarget } from '../../actions/app/navigation.actions'

const vehicleCodeMiddleware =
    (apiRequest: ApiRequest): Middleware =>
    (store) =>
    (next) =>
    (action) => {
        next(action)
        const { dispatch, getState } = store

        if (isActionOf(createVehicleCode, action)) {
            dispatch(resetVehicleCodeState())

            apiRequest(
                {
                    options: {
                        url: getCreateVehiclecodeApiUrl(),
                        method: 'POST',
                    },
                    asyncActions: {
                        request: createVehicleCodeAsync.request,
                        success: action.meta?.onSuccess || createVehicleCodeAsync.success,
                        failure: action.meta?.onFailure || createVehicleCodeAsync.failure,
                    },
                    causedBy: action,
                },
                dispatch,
            )
        }

        if (isActionOf(createVehicleCodeAsync.success, action)) {
            const { vehicleCode } = action.payload

            dispatch(setVehicleCodeState(vehicleCode))
        }

        if (isActionOf(loadConfigurationByVehicleCode, action)) {
            const vehicleCode = action.payload

            apiRequest(
                {
                    options: {
                        url: loadConfigurationByVehiclecodeApiUrl(vehicleCode),
                        method: 'POST',
                    },
                    asyncActions: loadConfigurationByVehicleCodeAsync,
                    causedBy: action,
                },
                dispatch,
            )
        }

        if (isActionOf(fetchEntryDataAsync.success, action)) {
            const initStateParams = initParametersSelector(getState())

            if (initStateParams.vehicleCode !== undefined) {
                dispatch(loadConfigurationByVehicleCode(initStateParams.vehicleCode))
            }
        }

        if (isActionOf(vehicleCodeChanged, action)) {
            const initStateParams = initParametersSelector(getState())
            const sessionIdIsSet = sessionIdIsSetSelector(getState())

            if (initStateParams.vehicleCode !== undefined && sessionIdIsSet) {
                dispatch(loadConfigurationByVehicleCode(initStateParams.vehicleCode))
            }
        }

        if (isActionOf(loadConfigurationByVehicleCodeAsync.success, action)) {
            const { status, rescueInfo } = action.payload

            if (status === 'OK') {
                dispatch(resetVehicleCodeInState())
                dispatch(fetchConfiguration())
                dispatch(fetchCart())
                dispatch(fetchEquipmentGroups())
                dispatch(fetchVisualization())
                dispatch(setShouldNavigateToTarget(true))
            } else {
                dispatch(setVehicleCodeStatus({ status, rescueInfo, code: (action as any).meta?.causedBy.payload }))
            }
        }
    }

export default vehicleCodeMiddleware
