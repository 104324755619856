import { connect, MapStateToProps } from 'react-redux'
import modelFilterOptionsSelector from '../../redux/selectors/models/modelFilterOptionsSelector'
import { applyFilter, resetFiltersState } from '../../redux/actions/app/filter.actions'
import { Props } from '../attribute-filters'
import filtersSelector from '../../redux/selectors/filtersSelector'

type StateProps = Pick<Props, 'filterOptions' | 'selectedFilters'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    filterOptions: modelFilterOptionsSelector(state),
    selectedFilters: filtersSelector(state),
})

type DispatchProps = Pick<Props, 'applyFilter' | 'resetFilters'>

const mapDispatchToProps: DispatchProps = {
    applyFilter,
    resetFilters: resetFiltersState,
}

export default connect(mapStateToProps, mapDispatchToProps)
