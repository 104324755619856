import {
    ROUTE_CARLINES,
    ROUTE_CHECKOUT,
    ROUTE_MODELS,
    ROUTE_OVERVIEW,
    ROUTE_TECHNICAL_DATA,
} from '../../../../constants/routes'
import languageSelector from '../../../selectors/i18n/languageSelector'
import countrySelector from '../../../selectors/i18n/countrySelector'
import activeNavLinkTitleSelector from '../../../selectors/activeNavLinkTitleSelector'
import currentEquipmentGroupSelector from '../../../selectors/equipments/currentEquipmentGroupSelector'
import i18n from '../../../../i18n/i18n'
import { State } from '../../../reducers/rootReducer'
import config from '../../../../config'

const routesPageIdMap: Record<string, string> = {
    [ROUTE_CARLINES]: 'dcc carline',
    [ROUTE_MODELS]: 'dcc engine',
    [ROUTE_TECHNICAL_DATA]: 'dcc technical-data',
    [ROUTE_OVERVIEW]: 'dcc your-configuration',
    [ROUTE_CHECKOUT]: 'dcc success-page',
}

const equipmentIdPageIdMap: Record<string, string> = {
    '01\\860': 'Colors',
    '01\\820': 'Standards',
    '01\\823': 'Media equipments',
    '97\\900': 'Standard',
    '02\\810': 'Standards',
    '01\\840': 'Accessories',
    '02\\840': 'Accessories',
    '02\\870': 'Special colors',
    '02\\890': 'Special installations',
    '01\\310': 'The color combinations',
    '01\\330': 'The special equipments',
    '01\\340': 'The special lacquer finishes',
    '01\\350': 'The special installations',
    '97\\901': 'The standard',
    '01\\710': 'Colors',
    '01\\715': 'Special lacquer finishes',
    '01\\780': 'Special equipments',
    '01\\785': 'Countries/voice control',
    '01\\790': 'Service center for special equipments',
}

const getPageId = (route, equipmentGroupId): string => {
    if (routesPageIdMap[route] !== undefined) {
        return routesPageIdMap[route]
    }

    if (equipmentIdPageIdMap[equipmentGroupId] !== undefined) {
        return `dcc ${equipmentIdPageIdMap[equipmentGroupId].toLocaleLowerCase()}`
    }

    return `dcc ${route}`
}

type PrevPath = {
    path: string
    isReferrer: boolean
}

const createPageObj = (state: State, prevPath: PrevPath, nextPath: string): Object => {
    const language = languageSelector(state)
    const country = countrySelector(state)
    const activeNavLinkTitle = activeNavLinkTitleSelector(state)
    const currentEquipmentGroup = currentEquipmentGroupSelector(state)
    const title = i18n.exists('title')
        ? `${i18n.t('title')} - ${i18n.t(activeNavLinkTitle)}`
        : 'Dealer Configurator'

    const referringURL = prevPath.isReferrer
        ? prevPath.path
        : `${window.location.origin}${prevPath.path}`

    return {
        pageInfo: {
            pageID: getPageId(nextPath, currentEquipmentGroup?.id),
            pageName: title,
            destinationURL: window.location.href,
            referringURL,
            sysEnv: config.STAGE,
            breadCrumbs: [getPageId(nextPath, currentEquipmentGroup?.id)],
            market: country,
            language,
        },
        category: {
            pageType: nextPath === ROUTE_OVERVIEW ? 'configurationSummary' : 'configurableModel',
            displayType: 'default',
        },
        attributes: {
            applicationName: 'dcc-main',
            applicationVersion: config.APP_VERSION,
            implementer: 1,
            site: 'dcc',
        },
    }
}

export default createPageObj
