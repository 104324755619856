import React, { useMemo, useState } from 'react'
import Select from 'react-select'
import { Tr, Td } from '../table'
import style from '../fast-configuration-modal/fastConfigurationModal.css'
import useTranslation from '../../hooks/useTranslation'

const FastConfigurationModelAutocomplete = ({ models, selectedModel, setSelectedModel, isLoading }) => {
    const { t } = useTranslation()
    const [inputValue, setInputValue] = useState('')

    const modelOptions = useMemo(() => models.map((model) => {
        const [mbvId, carlineId] = model.id.split('-')
        return {
            value: `${carlineId}|${mbvId}`,
            label: model.id,
        }
    }), [models])

    const filteredModelOptions = useMemo(() => inputValue.length >= 3
        ? modelOptions.filter(option =>
            option.label.toLowerCase().includes(inputValue.toLowerCase()),
          )
        : [], [inputValue, modelOptions])

    return (
        <Tr>
            <Td className={style.noBorder}>
                <div className={style.explanationContainer}>
                    <h4 className={style.explanationHeading}>
                        {t('fastConfigurationModal.modelTitle')}
                    </h4>
                    <span className={style.explanation}>
                        {t('fastConfigurationModal.modelExplanation')}
                    </span>
                </div>
            </Td>
            <Td className={style.noBorder}>
                <Select
                    options={filteredModelOptions}
                    value={selectedModel}
                    onChange={(selected) => setSelectedModel(selected || null)}
                    onInputChange={(value) => setInputValue(value)}
                    isMulti={false}
                    isSearchable
                    placeholder=""
                    className={style.input}
                    isDisabled={isLoading}
                    components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                    }}
                    noOptionsMessage={() =>
                        inputValue.length < 3
                            ? t('fastConfigurationModal.atLeastThreeCharacters')
                            : t('fastConfigurationModal.noModelFound')
                    }
                    isClearable={true}
                />
            </Td>
        </Tr>
    )
}

export default FastConfigurationModelAutocomplete
