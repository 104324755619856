import { connect, MapStateToProps } from 'react-redux'
import { Props } from './AttributeFilterResultPlaceholder'
import filtersSelector from '../../redux/selectors/filtersSelector'

type StateProps = Pick<Props, 'selectedFilters'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    selectedFilters: filtersSelector(state),
})

export default connect(mapStateToProps)
