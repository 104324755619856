import React, { FunctionComponent } from 'react'
import { Route, Switch } from 'react-router-dom'
import ModelSelection from '../model-selection'
import EquipmentSelection from '../equipment-selection'
import Overview from '../overview-view'
import TechnicalDataView from '../technical-data-view'
import Checkout from '../checkout'
import Abort from '../abort'
import NotFound from '../not-found'
import Check from '../check'
import urlifyGroupName from '../../helpers/urlifyGroupName'
import BasePathHandler from './BasePathHandler'
import ComparisonWrapper from '../comparison-wrapper'
import LastConfigurations from '../last-configurations'
import UserGarage from '../user-garage'

export type Props = {
    equipmentPaths: string[]
}

const Router: FunctionComponent<Props> = (props) => {
    const { equipmentPaths } = props

    const equipmentGroupsRoutes = equipmentPaths.map((equipmentPath) => (
        <Route
            key={equipmentPath}
            path={urlifyGroupName(equipmentPath)}
            component={EquipmentSelection}
        />
    ))

    return (
        <Switch>
            <Route exact path={["/", "/entry"]} component={BasePathHandler} />
            <Route path="/models" component={ModelSelection} />
            <Route path="/overview" component={Overview} />
            <Route path="/technical-data" component={TechnicalDataView} />
            {equipmentGroupsRoutes}
            <Route path="/checkout" component={Checkout} />
            <Route path="/abort" component={Abort} />
            <Route path="/check" component={Check} />
            <Route path="/compare" component={ComparisonWrapper} />
            <Route path="/last-configurations" component={LastConfigurations} />
            <Route path="/garage" component={UserGarage} />
            <Route component={NotFound} />
        </Switch>
    )
}

export default Router
