import React, { FunctionComponent } from 'react'
import useTranslation from '../../hooks/useTranslation'
import CarlineGroup from '../../entities/CarlineGroup'
import CarlineGridGroup from '../carline-grid-group'
import style from './carlineGridView.css'
import CarlineGridViewPlaceholder from './CarlineGridViewPlaceholder'
import CarlineFilters from '../carline-filters'
import FilterIcon from '../../assets/media/icons/filter.svg'
import AttributeFilterResultPlaceholder from '../attribute-filters-placeholder'

export type Props = {
    carlineGroups: CarlineGroup[]
    isLoading: boolean
    filtersAreVisible: boolean
    carlineFiltersAreLoading: boolean
    toggleFiltersAreVisible: () => void
}

const CarlineGridView: FunctionComponent<Props> = (props) => {
    const {
        carlineGroups,
        isLoading,
        filtersAreVisible,
        carlineFiltersAreLoading,
        toggleFiltersAreVisible,
    } = props
    const { t } = useTranslation()
    const filtersOrCarlinesAreLoading = isLoading || carlineFiltersAreLoading
    const placeHolder = (carlineGroups.length === 0 && isLoading)
        ? <CarlineGridViewPlaceholder />
        : null

    const carlineGroupToggleElements = carlineGroups.map((carlineGroup) => (
        <CarlineGridGroup
            key={carlineGroup.id}
            carlineGroup={carlineGroup}
        />
    ))

    const availableCarlines = carlineGroups?.flatMap((carlineGroup) => carlineGroup.carlines)

    const toggleFilters = (): void => {
        toggleFiltersAreVisible()
    }

    const toggleButtonLabel = filtersOrCarlinesAreLoading
        ? t('loading')
        : filtersAreVisible
            ? t('carlineSelection.hideFilters')
            : t('carlineSelection.showFilters')

    return (
        <>
            <div className={style.toggleButtonWrapper}>
                <button
                    className={style.toggleButton}
                    onClick={toggleFilters}
                    type="button"
                    data-cy="filter-toggle"
                    disabled={carlineFiltersAreLoading}
                >
                    {toggleButtonLabel}
                    <FilterIcon className={style.icon} />
                </button>
            </div>
            <div hidden={!filtersAreVisible}>
                <CarlineFilters isLoading={filtersOrCarlinesAreLoading} layout="horizontal" />
            </div>
            <AttributeFilterResultPlaceholder hasAvailableResults={availableCarlines?.length !== 0}>
                <div className={style.list}>
                    {placeHolder}
                    {carlineGroupToggleElements}
                </div>
            </AttributeFilterResultPlaceholder>
        </>
    )
}

export default CarlineGridView
