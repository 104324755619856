import { createReducer } from 'typesafe-actions'
import {resetAllModelsDataState, setAllModelsDataSate} from '../actions/app/models.actions'
import ModelData from '../../entities/ModelData'

export type ModelDataState = ModelData

export const defaultState: ModelDataState = {
    models: [],
    remarks: [],
}

const allModelsDataReducer = createReducer(defaultState)
    .handleAction(setAllModelsDataSate, (state, action) => action.payload)
    .handleAction(resetAllModelsDataState, () => defaultState)

export default allModelsDataReducer
